// export const apiBota: string = 'https://developer-api-bota.aokitech.com.ar';
// export const apiUrl: string = 'https://developer-api-bota.aokitech.com.ar';
export const apiBota: string = 'https://api-bota.aokitech.com.ar';
export const apiUrl: string = 'https://api-bota.aokitech.com.ar';
export const apiMeLi: string = 'https://developer-api-bota.aokitech.com.ar:444';
//export const apiMeLi: string = 'http://localhost:8000';
// export const apiMeLi: string = 'http://35.174.39.125:8081/';
export const registerMeliUrl: string = 'https://auth.mercadolibre.com/authorization?response_type=code&client_id=3693630284082274';
export const apiCROMO: string = 'https://api-cromo-v2.rj.r.appspot.com';
/* export const apiCROMO: string = 'http://localhost:3000'; */
export const authBaseUrl = 'https://auth-api-427516.uc.r.appspot.com';
export const chabotMicroserivceUrl = 'https://menu-chatbot-microservice.uc.r.appspot.com'
export { };